<template>
  <div class="image-uploader-container-new" v-bind:class="{ error: showError }">
    <a-upload
      name="uploadFile"
      accept="image/*"
      :headers="headerUpload"
      :action="urlUpload"
      listType="picture-card"
      :fileList="fileList"
      :showUploadList="showUploadList"
      @preview="handlePreview"
      @change="handleChange"
      :class="className"
    >
      <div v-if="fileList.length < maxFile">
        <a-icon type="plus" />
        <div class="ant-upload-text">Upload</div>
      </div>
    </a-upload>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>

    <label v-if="subject" class="label">{{ subject }}</label>
    <div v-if="showAlt" class="form-group form-group-row alt-text">
      Alt text
      <input
        class="form-control"
        placeholder="Descriptions of an image"
        :required="isRequired"
        type="text"
        ref="textAlt"
        :value="altData"
        @change="onAltChange($event.target.value)"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    subject: String,
    dataImage: Object,
    uploadSetting: Object,
    isRequired: {
      type: Boolean,
      default: false
    },
    showError: {
      type: Boolean,
      default: false
    },
    maxFile: {
      type: Number,
      default: 1
    },
    className: {
      type: String,
      default: 'maxWidth'
    },
    onFileUploaded: Function
  },
  data () {
    return {
      urlUpload: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/upload`,
      headerUpload: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem('cmsUser')).token
        }`
      },
      previewVisible: false,
      previewImage: '',
      showUploadList: {
        showPreviewIcon: false,
        showRemoveIcon: true
      },
      showAlt: true,
      altData: '',
      fileList: []
    }
  },
  methods: {
    handleCancel () {
      this.previewVisible = false
    },
    handlePreview (file) {
      this.previewImage = file.url
      this.previewVisible = true
    },
    handleChange ({ fileList }) {
      this.fileList = fileList

      if (fileList.length) {
        if (fileList[0].status === 'done') {
          this.fileList = [
            {
              uid: '-1',
              name: fileList[0].response.data.url,
              status: 'done',
              url: `${process.env.VUE_APP_WEBSITE_ENDPOINT}${fileList[0].response.data.url}`
            }
          ]

          this.onFileUploaded({
            source: fileList[0].response.data.url,
            name: this.altData
          })
        }
      } else {
        this.onFileUploaded({
          source: '',
          name: ''
        })
      }
    },
    onAltChange ($value) {
      this.altData = $value

      this.onFileUploaded({
        source: this.fileList[0].name,
        name: this.altData
      })
    }
  },
  created () {
    if (this.dataImage) {
      if (this.dataImage.source) {
        this.fileList = [
          {
            uid: '-1',
            name: this.dataImage.source,
            status: 'done',
            url: `${process.env.VUE_APP_WEBSITE_ENDPOINT}${this.dataImage.source}`
          }
        ]
      }

      if (this.dataImage.name) {
        this.altData = this.dataImage.name
      }
    }

    if (this.uploadSetting) {
      this.showUploadList.showPreviewIcon =
        this.uploadSetting.showPreview !== undefined ||
        this.uploadSetting.showPreview !== null
          ? this.uploadSetting.showPreview
          : false
      this.showUploadList.showRemoveIcon =
        this.uploadSetting.showDelete !== undefined ||
        this.uploadSetting.showDelete !== null
          ? this.uploadSetting.showDelete
          : true
      this.showAlt =
        this.uploadSetting.showAlt !== undefined ||
        this.uploadSetting.showAlt !== null
          ? this.uploadSetting.showAlt
          : true
    }
  },
  watch: {
    dataImage: function ($value, $oldValue) {
      if ($value.source) {
        this.fileList = [
          {
            uid: '-1',
            name: $value.source,
            status: 'done',
            url: `${process.env.VUE_APP_WEBSITE_ENDPOINT}${$value.source}`
          }
        ]

        this.altData = $value.name
      }
    },
    uploadSetting: function ($value, $oldValue) {
      if ($value) {
        this.showUploadList.showPreviewIcon = $value.showPreview
        this.showUploadList.showRemoveIcon = $value.showDelete
        this.showAlt =
          $value.showAlt !== undefined || $value.showAlt !== null
            ? $value.showAlt
            : true
      }
    }
  }
}
</script>

<style>
.upload-item-container {
  text-align: left;
  flex: 1;
  padding: 10px;
}

.image-uploader-container-new span {
  display: flex;
}
.anticon.anticon-eye-o {
  vertical-align: text-top;
}

/* .maxWidth .ant-upload-list {
  width: inherit;
} */

.maxWidth .ant-upload.ant-upload-select-picture-card {
  width: 100%;
  height: auto;
}

.maxWidth .ant-upload-list-picture-card .ant-upload-list-item {
  width: 100%;
  height: 100%;
}

.maxWidth .ant-upload-list-picture-card-container {
  width: 100%;
  height: auto;
}

.bannerUpload .ant-upload.ant-upload-select-picture-card {
  width: 100%;
  height: 250px;
}

.bannerUpload .ant-upload-list-picture-card .ant-upload-list-item {
  width: 100%;
  height: 250px;
}

.alt-text {
  color: rgba(0, 0, 0, 0.65) !important;
}

.error .ant-upload.ant-upload-select-picture-card {
  border: 1px dashed #e40404;
}

.error .ant-upload.ant-upload-select-picture-card i,
.error .ant-upload.ant-upload-select-picture-card div.ant-upload-text {
  color: #e40404;
}

.ant-upload-list-picture-card .ant-upload-list-item-info {
  background: #e0e0e0;
}

.ant-upload-list-picture-card .ant-upload-list-item-info::before {
  width: 100%;
}

.ant-upload-list-item-image {
  font-size: 14px;
}

.ant-upload-list-picture-card .ant-upload-list-item-info:before {
  left: 0 !important;
}
</style>
