<template>
  <div>
    <!-- <froala :config="configEditor" v-model="editorValue"></froala> -->
    <div v-if="modal === modalShown">
      <EditorTinymce
        api-key="xq2xssn45j4kf05zs7zq43rfnpq46b0vx74ruysf1bpccwec"
        v-model="editorValue"
        @onInit="onInitTinyMCE"
        :init="editorInit"
        ref="tinymce"
      />
    </div>
    <!-- <input type="fie" ref="inputEditortypefile" accept="image"/> -->
  </div>
</template>

<script>
import EditorTinymce from '@tinymce/tinymce-vue'
import { mapActions } from 'vuex'
import { ActionView } from '@/store/actionTypes'
import axios from 'axios'

export default {
  props: {
    content: {
      type: String,
      default: ''
    },
    modal: {
      type: Boolean,
      default: false
    },
    modalShown: {
      type: Boolean,
      default: false
    },
    configEditor: {
      type: Object,
      default: () => {
        return {
          toolbarButtons: [
            'paragraphFormat',
            'bold',
            'italic',
            'underline',
            'strikeThrough',
            'subscript',
            'superscript',
            'fontFamily',
            'fontSize',
            'textColor',
            '|',
            'align',
            'formatOL',
            'formatUL',
            'outdent',
            'indent',
            'insertLink',
            'insertImage',
            'insertVideo',
            'insertTable',
            '|',
            'undo',
            'redo',
            'html',
            'clearFormatting'
          ],
          key:
            're1H1qA3B1B5B5C5G4hBi1a2d2Za1IXBh1f1THTBPLIIWBORpF1F1E1D4F1C11A7C2E5D3==',
          events: {
            'image.beforeUpload': function (files) {
              const editor = this
              if (files.length) {
                const user = JSON.parse(localStorage.getItem('cmsUser'))
                const url = `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/upload`
                const formData = new FormData()
                const config = {
                  responseType: 'json',
                  headers: {
                    Authorization: `Bearer ${user.token}`
                  }
                }
                formData.append('uploadFile', files[0])
                axios.post(url, formData, config).then(($res) => {
                  if ($res.data.success) {
                    editor.image.insert(
                      `${process.env.VUE_APP_WEBSITE_ENDPOINT}${$res.data.data.url}`,
                      null,
                      { class: 'img-fluid' },
                      editor.image.get()
                    )
                  }
                })
              }
              return false
            }
          }
        }
      }
    },
    onUpdateInfo: Function
  },
  components: {
    EditorTinymce
  },
  data () {
    return {
      editorInit: {
        height: 400,
        menubar: false,
        automatic_uploads: true,
        image_title: 'default',
        image_dimensions: false,
        images_upload_handler: this.imageuploadhandler,
        images_upload_url: '',
        image_class_list: [{ title: 'default', value: 'img-fluid' }],
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount imagetools'
        ],
        toolbar: [
          `formatselect| bold italic underline strikethrough| subscript superscript | fontselect fontsizeselect backcolor forecolor |
          bullist numlist  alignleft aligncenter alignright alignjustify alignnone |
          `,
          `table outdent indent link image media
          blockquote |copy paste cut  selectall| lineheight | newdocument | remove |
          visualaid | lists | preview | undo redo code removeformat`
        ]
      },
      editor: null,
      editorValue: ''
    }
  },
  methods: {
    ...mapActions({
      loading: ActionView.SET_LOADING_SCREEN
    }),
    onInitTinyMCE (e) {
      e.target.setContent(this.editorValue)
    },
    imageuploadhandler (blobInfo, success, failure, progress) {
      const blob = blobInfo.blob()
      if (blob) {
        const user = JSON.parse(localStorage.getItem('cmsUser'))
        const url = `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/upload`
        const formData = new FormData()
        const config = {
          responseType: 'json',
          headers: {
            Authorization: `Bearer ${user.token}`
          }
        }
        formData.append('uploadFile', blob, blobInfo.filename())
        axios.post(url, formData, config).then(($res) => {
          if ($res.data.success) {
            success(
              `${process.env.VUE_APP_WEBSITE_ENDPOINT}${$res.data.data.url}`
            )
          } else {
            failure(`${$res.data.message}`)
          }
        })
      }
    },
    openModal (command) {
      this.$refs.ytmodal.showModal(command)
    },
    addCommand (data) {
      if (data.command !== null) {
        data.command(data.data)
      }
    },
    onUpdateContentEmit ($value) {
      this.onUpdateInfo($value)
      if (this.content !== $value && !!this.onUpdateInfo) {
        this.onUpdateInfo($value)
      }
    }
  },
  beforeDestroy () {
    this.$refs.tinymce.editor.destroy()
  },
  beforeMount () {
    // images_upload_handler: this.imageuploadhandler,
    this.loading(true)
  },
  mounted () {
    this.$nextTick(function () {
      this.editorValue = this.content
    })
    this.loading(false)
  },
  watch: {
    modalShown: function (val) {
      if (this.modal && !val) {
        this.$refs.tinymce.editor.destroy()
      }
    },
    content: {
      handler: function (value, old) {
        this.editorValue = value
      },
      deep: true
    },
    editorValue: function (value, old) {
      this.onUpdateContentEmit(value)
    }
  }
}
</script>
<style lang="scss">
$color-black: #000000;
$color-white: #ffffff;
$color-grey: #dddddd;

.editor {
  border: 1px solid #ccc;
  .menubar {
    margin-bottom: 1rem;
    transition: visibility 0.2s 0.4s, opacity 0.2s 0.4s;
    padding: 0.5em;

    &.is-hidden {
      visibility: hidden;
      opacity: 0;
    }

    &.is-focused {
      visibility: visible;
      opacity: 1;
      transition: visibility 0.2s, opacity 0.2s;
    }

    &__button {
      font-weight: bold;
      display: inline-flex;
      background: transparent;
      border: 0;
      color: $color-black;
      padding: 0.2rem 0.5rem;
      margin-right: 0.2rem;
      border-radius: 3px;
      cursor: pointer;

      &:hover {
        background-color: rgba($color-black, 0.05);
      }

      &.is-active {
        background-color: rgba($color-black, 0.1);
      }
    }

    span#{&}__button {
      font-size: 13.3333px;
    }
  }

  &__content {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    padding: 0.5em;
    border-top: 1px solid #ccc;

    * {
      caret-color: currentColor;
    }
    pre {
      padding: 0.7rem 1rem;
      border-radius: 5px;
      background: $color-black;
      color: $color-white;
      font-size: 0.8rem;
      overflow-x: auto;

      code {
        display: block;
      }
    }
    .ProseMirror-focused {
      outline: none;
    }
    p code {
      display: inline-block;
      padding: 0 0.4rem;
      border-radius: 5px;
      font-size: 0.8rem;
      font-weight: bold;
      background: rgba($color-black, 0.1);
      color: rgba($color-black, 0.8);
    }

    ul,
    ol {
      padding-left: 1rem;
    }

    li > p,
    li > ol,
    li > ul {
      margin: 0;
    }

    a {
      color: inherit;
    }

    blockquote {
      border-left: 3px solid rgba($color-black, 0.1);
      color: rgba($color-black, 0.8);
      padding-left: 0.8rem;
      font-style: italic;

      p {
        margin: 0;
      }
    }

    img {
      max-width: 100%;
      border-radius: 3px;
    }

    table {
      border-collapse: collapse;
      table-layout: fixed;
      width: 100%;
      margin: 0;
      overflow: hidden;

      td,
      th {
        min-width: 1em;
        border: 2px solid $color-grey;
        padding: 3px 5px;
        vertical-align: top;
        box-sizing: border-box;
        position: relative;

        > * {
          margin-bottom: 0;
        }
      }

      th {
        font-weight: bold;
        text-align: left;
      }

      .selectedCell:after {
        z-index: 2;
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(200, 200, 255, 0.4);
        pointer-events: none;
      }

      .column-resize-handle {
        position: absolute;
        right: -2px;
        top: 0;
        bottom: 0;
        width: 4px;
        z-index: 20;
        background-color: #adf;
        pointer-events: none;
      }
    }

    .tableWrapper {
      margin: 1em 0;
      overflow-x: auto;
    }

    .resize-cursor {
      cursor: ew-resize;
      cursor: col-resize;
    }
  }
}

[data-strclass="img-fluid"] {
  cursor: pointer;
}
</style>
