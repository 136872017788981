<template>
  <div id="app">
    <file-pond
      name="file-upload"
      ref="pond"
      :class="[className]"
      :labelIdle="title"
      :allow-multiple="multiple"
      :accepted-file-types="acceptedFileTypes"
      :server="this.server()"
      :instantUpload="instantUpload"
      :allowDrop="false"
      :files="prepareFiles()"
      :maxFileSize="maxFileSize"
      :onremovefile="onRemoveFile"
      :onprocessfiles="onprocessfiles"
      :onprocessfilestart="onprocessfilestart"
      :onupdatefiles="onupdatefiles"
      :allowImagePreview="allowImagePreview"
    />
  </div>
</template>

<script>
import axios from 'axios'
import vueFilePond from 'vue-filepond'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'

import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize
)

export default {
  props: {
    type: {
      type: String,
      default: 'image'
    },
    instantUpload: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: true
    },
    acceptedFileTypes: {
      type: String,
      default: 'image/jpg, image/jpeg, image/png'
    },
    maxFileSize: {
      type: String,
      default: '2MB'
    },
    title: {
      type: String,
      default: 'Select file'
    },
    files: {
      type: Array,
      default: () => []
    },
    onUploaded: {
      type: Function,
      default: () => {}
    },
    index: Number,
    allowImagePreview: {
      type: Boolean,
      default: true
    },
    className: {
      type: String,
      default: ''
    },
    onRemoveItemFile: {
      type: Function,
      default: (index) => {}
    }
  },
  data: () => ({
    progressCount: 0
  }),
  components: {
    FilePond
  },
  methods: {
    server: function () {
      return {
        fetch: async (url, load, error, progress, abort) => {
          try {
            url = /^http*/.test(url)
              ? url
              : `${process.env.VUE_APP_WEBSITE_ENDPOINT}${url}`
            const response = await axios({
              method: 'get',
              url,
              responseType: 'blob'
            })
            if (response.data) load(response.data)
          } catch (e) {
            error(
              (e.response && e.response.data && e.response.data.error) ||
                e.message
            )
          }

          return {
            abort: () => {
              abort()
            }
          }
        },
        load: async (url, load, error, progress, abort) => {
          try {
            const response = await axios({
              method: 'get',
              url,
              responseType: 'blob'
            })
            if (response.data) load(response.data)
          } catch (e) {
            error(
              (e.response && e.response.data && e.response.data.error) ||
                e.message
            )
          }

          return {
            abort: () => {
              abort()
            }
          }
        },
        process: async (
          fieldName,
          file,
          metadata,
          load,
          error,
          progress,
          abort
        ) => {
          try {
            const user = JSON.parse(localStorage.getItem('cmsUser'))
            const formData = new FormData()
            formData.append('uploadFile', file)
            const config = {
              responseType: 'json',
              headers: {
                Authorization: `Bearer ${user.token}`
              },
              onUploadProgress: (progressEvent) => {
                progress(true, progressEvent.loaded, progressEvent.total)
              }
            }
            const url = `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/upload`
            const response = await axios.post(url, formData, config)
            const { data } = response
            if (data.success) {
              load(data.data.url)
            }
          } catch (e) {
            error(
              (e.response && e.response.data && e.response.data.error) ||
                e.message
            )
          }

          return {
            abort: () => {
              abort()
            }
          }
        }
      }
    },
    getImageList: function () {
      const fileData = this.$refs.pond
        ? this.$refs.pond
          .getFiles()
          .filter((file) => {
            return !!file.serverId
          })
          .map((file) => file.serverId)
        : null

      return fileData
    },
    onRemoveFile: function () {
      if (this.progressCount === 0) this.onRemoveItemFile(this.index)
      this.progressCount = 0
    },
    onprocessfilestart: function (file) {
      this.progressCount = 0
    },
    onprocessfiles: function () {
      const imgList = this.getImageList()

      if (imgList && imgList.length > 0) {
        this.setFiles(imgList)
      }
    },
    onupdatefiles: async function (file) {
      await file.map(($file) => {
        return $file.serverId
      })
    },

    setFiles: function (response) {
      this.files.forEach((file, index) => {
        this.files[index].source = response[index]
      })
      let resp = this.files

      if (resp.length === 0 && response) {
        resp = response.map((file) => ({
          source: file
        }))
      }
      this.onUploaded(this.index, resp)
    },
    prepareFiles: function () {
      return this.files.map((each) => ({
        ...each,
        source: `${process.env.VUE_APP_WEBSITE_ENDPOINT}${each.source}`
      }))
    }
  }
}
</script>

<style lang="scss">
.label {
  font-weight: bold;
}
.filepond--drop-label {
  background-color: #2698fb !important;
  border-color: #2698fb;
}
.wrapper-btn-primary-upload .filepond--drop-label label {
  color: #fff;
}
.file-upload-gallery {
  .filepond--root {
    .filepond--panel-root {
      background-color: transparent;
    }
  }
  .filepond--drop-label {
    margin: 0 auto 10px auto;
    width: 180px;
    font-size: 14px;
    border-radius: 5px;
  }
  .filepond--root .filepond--list-scroller {
    margin-top: 3em;
    ul {
      margin-top: 0.6em;
    }
  }
}
</style>
