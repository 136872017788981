<template>
  <div>
    <a-upload-dragger
      name="uploadFile"
      accept="application/pdf"
      :multiple="false"
      :headers="headerUpload"
      :action="urlUpload"
      :fileList="fileList"
      :remove="handleRemove"
      @change="handleChange"
    >
      <p class="ant-upload-drag-icon">
        <a-icon type="inbox" />
      </p>
      <p class="ant-upload-text">Click or drag file to this area to upload</p>
      <p class="ant-upload-hint">
        Support for a single or bulk upload. Strictly prohibit from uploading
        company data or other band files
      </p>
    </a-upload-dragger>
  </div>
</template>

<script>
export default {
  props: {
    dataPdf: Object,
    test: String,
    onFileUploaded: Function
  },
  data () {
    return {
      urlUpload: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/upload`,
      headerUpload: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem('cmsUser')).token
        }`
      },
      fileList: []
    }
  },
  methods: {
    handleChange (info) {
      this.fileList = info.fileList.map(($objFileList) => {
        return Object.assign({}, $objFileList)
      })
      const status = info.file.status

      if (status === 'done') {
        this.fileList = [
          {
            uid: '-1',
            name: `${info.file.response.data.url}`,
            status: 'done',
            url: `${process.env.VUE_APP_WEBSITE_ENDPOINT}${info.file.response.data.url}`
          }
        ]

        this.onFileUploaded([
          {
            source: `${info.file.response.data.url}`,
            status: 'Active'
          }
        ])
      }
    },
    handleRemove () {
      this.fileList = []
      this.onFileUploaded([])
    },
    initialSystem () {
      if (this.dataPdf.source) {
        this.fileList = [
          {
            uid: '-1',
            name: `${this.dataPdf.source}`,
            status: 'done',
            url: `${process.env.VUE_APP_WEBSITE_ENDPOINT}${this.dataPdf.source}`
          }
        ]
      }
    }
  },
  created () {
    this.initialSystem()
  },
  watch: {
    dataPdf: function ($value, $oldValue) {
      if ($value) {
        this.fileList = [
          {
            uid: '-1',
            name: `${$value.source}`,
            status: 'done',
            url: `${process.env.VUE_APP_WEBSITE_ENDPOINT}${$value.source}`
          }
        ]
      }
    }
  }
}
</script>
