<template>
  <div id="app">
    <div :class="{ 'upload-error': uploadFileErrorRequired }">
      <UploadMulti
        name="files-upload"
        :title="title"
        :multiple="multiple"
        :instantUpload="instantUpload"
        :acceptedFileTypes="acceptedFileTypes"
        :maxFileSize="maxFileSize"
        :files="files"
        :onUploaded="onUploaded"
        :index="0"
        :allowImagePreview="allowImagePreview"
        :className="className"
      />
      <b-button
        block
        variant="outline-primary"
        @click="addFile"
        v-if="enableAddRow"
        >+ Add</b-button
      >
      <div v-if="uploadFileErrorRequired" class="text-error">
        * File(s) is required.
      </div>
    </div>
  </div>
</template>

<script>
import format from 'date-fns/format'
import UploadMulti from './UploadMulti'

export default {
  props: {
    type: {
      type: String,
      default: 'file'
    },
    instantUpload: {
      type: Boolean,
      default: false
    },
    enableAddRow: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    acceptedFileTypes: {
      type: String,
      default: 'image/jpg, image/jpeg, image/png'
    },
    maxFileSize: {
      type: String,
      default: '2MB'
    },
    header: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default:
        '<div class="image-input-btn large"><i class="fal fa-plus"></i></div>'
    },
    subject: {
      type: String,
      default: ''
    },
    files: {
      type: Array,
      default: () => []
    },
    className: {
      type: String,
      default: 'image-uploader-container'
    },
    onAltChange: {
      type: Function,
      default: () => {}
    },
    onFileUploaded: {
      type: Function,
      default: () => {}
    },
    uploadFileErrorRequired: {
      type: Boolean,
      default: false
    },
    allowImagePreview: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    showAlt: true
  }),
  methods: {
    renderDate (date) {
      return date ? format(date, 'YYYY-MM-DD') : '-'
    },
    onUploaded: async function (index, data) {
      const f = data.map((each) => {
        return {
          ...each,
          status: 'Active',
          updatedAt: this.renderDate(new Date())
        }
      })
      this.onFileUploaded(f)
    },
    addFile () {
      this.files.push({ source: null })
    }
  },
  components: {
    UploadMulti
  }
}
</script>
<style>
.filepond--drop-label {
  background: #007bff;
}
.filepond--drop-label label {
  color: #fff;
  font-size: 1.5em;
}
.image-uploader-container {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 1.5em;
}
.error {
  color: red;
  margin-bottom: 15px;
}
.upload-error {
  border: 1px solid red;
}
</style>
